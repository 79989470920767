



















































import { Vue, Component } from 'vue-property-decorator';
import { H3Icon, H3Button, H3Input } from '@h3/thinking-ui';

import CommonInput from './common-input.vue';

import { ModifyPasswordService, LoginService } from '@WorkPlatform/services';

import { RsaEncrypt } from '@WorkPlatform/common/rsg';

@Component({
  name: 'ChangePassword',
  components: {
    H3Icon,
    H3Button,
    H3Input,
    CommonInput,
  },
})
export default class ChangePassword extends Vue {
  mode: string = 'verification';

  // 密码
  password: string = '';

  newPassword: string = '';

  repeatNewPassword: string = '';

  // 确认密码的提示语
  reconfirmTip: string = '';

  // 新密码提示语
  newPasswordTip: string = '';

  // 是否有错误验证
  hasError: boolean = false;

  // 加密公钥
  rsaKey: any = {};

  passRuleData: any = {};

  // 是否可以下一步
  get verificationPassword () {
    return !this.password;
  }

  // 是否可以提交
  get submitPassword () {
    return !(this.password && this.newPassword && this.repeatNewPassword);
  }

  get userId () {
    let userInfo: any = localStorage.getItem('userInfo') || '';
    try {
      userInfo = JSON.parse(userInfo);
      return userInfo?.userId || '';
    } catch (error) {
      return '';
    }
  }

  typeOptions:any[]=[{
    label: '数字',
    value: 'digit',
  }, {
    label: '小写字母',
    value: 'lowercaseLetter',
  }, {
    label: '大写字母',
    value: 'uppercaseLetter',
  }, {
    label: '英文特殊字符（除空格）',
    value: 'specialCharacter',
  }];

  /**
   * 获取加密公钥
   */
  async getKey () {
    if (this.rsaKey.index) return true;
    const res: any = await LoginService.getKey();
    const { success, data, errMessage = '服务异常！' } = res;
    if (!success || !data.index || !data.key) {
      this.$message.warning(errMessage);
      this.rsaKey = {};
      return false;
    }
    this.rsaKey = data;
    return true;
  }

  /**
   * 验证原密码
   */
  async requestService () {
    this.$toast.show({
      icon: 'loading',
      text: '加载中',
      duration: 300,
    });
    await this.getKey();
    const oldPassword: any = RsaEncrypt(this.password, this.rsaKey.key);
    const params: any = {
      oldPassword,
      index: this.rsaKey.index,
    };
    const { success, data } = await ModifyPasswordService.checkOldPassword(
      params,
      this.userId,
    );
    if (success && data) {
      this.mode = 'check';
    } else {
      this.$toast.show('密码不正确！');
      return;
    }

    const res = await ModifyPasswordService.getPasswordRuleInfo();

    if (res.success && res.data.passwordRuleStatus) {
      this.passRuleData = res.data;
    }
  }

  /**
   * 设置新密码
   */
  async requestServiceSetNew () {
    this.reconfirmBlur();
    this.newPasswordBlur();
    if (this.hasError) return;
    const oldPassword: any = RsaEncrypt(this.password, this.rsaKey.key);
    const newPassword: any = RsaEncrypt(this.newPassword, this.rsaKey.key);
    const params: any = {
      oldPassword,
      newPassword,
      index: this.rsaKey.index,
    };
    const { success } = await ModifyPasswordService.modifyPassword(params, this.userId);
    if (success) {
      this.$message.success('设置成功！');
      localStorage.removeItem('sessToken');
      const wait: number = 1000;
      setTimeout(() => {
        this.updateHandler();
      }, wait);
    } else {
      this.$message.warning('设置新密码失败！');
    }
  }

  updateHandler () {
    localStorage.clear();

    this.$router.push({
      name: 'login',
      params: { type: 'user' },
    });
  }

  evil (fn) {
    // 本质是eval()，防止eslint报错
    const Fn = Function; // 一个变量指向Function，防止有些前端编译工具报错
    return new Fn('return ' + fn)();
  }

  /**
   * 新密码失教校验
   */
  newPasswordBlur () {
    const value = this.newPassword;
    const empty: number = 0;
    const maxLength: number = 32;

    if (this.passRuleData.regularExpression) {
      const reg = this.evil('/' + this.passRuleData.regularExpression + '/');
      if (!value) {
        this.newPasswordTip = '密码不能为空！';
      } else if (!reg.test(value)) {
        let str:any = '';
        this.typeOptions.forEach(item => {
          if (this.passRuleData[item.value]) {
            if (str.length === empty) {
              str += item.label;
            } else {
              str += '、' + item.label;
            }
          }
        });
        const tip: string = `至少包含${str}，最少6位，最高32位`;
        this.newPasswordTip = tip;
      } else {
        this.newPasswordTip = '';
      }
    } else {
      const reg = /^[A-Z](?=.*[0-9].*)(?=.*[a-z].*).{5,19}$/;
      if (!value) {
        this.newPasswordTip = '密码不能为空！';
      } else if (!reg.test(value)) {
        const tip: string = '请以大写字母开头,6~20位';
        this.newPasswordTip = tip;
      } else if (value.length > maxLength) {
        this.newPasswordTip = '密码长度不能超过32位';
      } else {
        this.newPasswordTip = '';
      }
    }

    this.hasError = !!this.newPasswordTip || !!this.reconfirmTip;
  }

  /**
   * 再次确认密码失教时间
   */
  reconfirmBlur () {
    if (this.repeatNewPassword !== this.newPassword) {
      this.reconfirmTip = '两次输入密码不一致';
      this.hasError = this.hasError || true;
    } else {
      this.reconfirmTip = '';
    }
    this.hasError = !!this.newPasswordTip;
  }

  /**
   * 初始化
   */
  init () {
    this.password = '';
    this.newPassword = '';
    this.repeatNewPassword = '';
    this.reconfirmTip = '';
  }

  async mounted () {
    this.init();
  }
}
